import React, { useEffect, useState, useMemo } from 'react'
import { Router } from '@reach/router' // included with gatsby
import { navigate } from 'gatsby'
// Components
import {
  Loading,
  ProductSummary,
  Seo,
  ShoppingWithBanner,
} from '../../components'
import RouterComponent from '../../components/RouterWrapper'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useStoreContext } from '../../context/StoreContext'
// Services
import { Magento } from '../../services/magento'

const Product = ({ url_key, productData }) => {
  const [product, setProduct] = useState(productData)

  const { storeData, isStoreLoading } = useStoreContext()

  const allProducts = useMemo(
    () => storeData['all-products']?.products ?? [],
    [storeData]
  )

  useEffect(() => {
    if (!isStoreLoading && !productData) {
      const productDataFind = allProducts.find(item => item.url_key === url_key)
      if (!productDataFind) {
        navigate('/')
      }
      setProduct(productDataFind)
    }
  }, [allProducts, url_key])

  return (
    <>
      <Seo title={product?.meta_title ?? ''} />
      <ShoppingWithBanner />
      <ProductSummary {...product} />
    </>
  )
}

const ProductPage = props => {
  const [productData, setProductData] = useState(null)
  const {
    prismicData: {
      prismicProductDetailPage: { loading_product },
    },
  } = usePrismic()

  const { isAuthLoading } = useAuthContext()
  const { isStoreLoading } = useStoreContext()

  useEffect(() => {
    if (!isStoreLoading) return
    if (isAuthLoading) return
    ;(async () =>
      await Magento.Store.getSingleProductByUrlKey({
        url_key: props.params['*'],
      })
        .then(({ products }) => {
          const findProduct = products.items.find(
            ({ url_key }) => url_key === props.params['*']
          )

          setProductData(findProduct)
        })
        .catch(e => console.log(e)))()
  }, [isAuthLoading])

  if (isStoreLoading && !productData)
    return <Loading loading={true} message={loading_product[0].text} />

  return (
    <>
      <Router primary={false} component={RouterComponent}>
        <Product path="/products/:url_key" productData={productData} />
      </Router>
    </>
  )
}

export default ProductPage
